<template>
  <div>
    <router-link :to="'/knowledge/'+ slug">
      <b-row>
        <b-col>
          <q-header level="6" size="" class="my-auto" :centered="false" color="var(--theme-accent-light)">
            <img src="~@/assets/images/site/blue_star.svg" alt="" class="star-icon">{{ $i18n.locale === 'ru' ? category : categoryEn || category}}
          </q-header>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <q-header color="var(--text-primary-color)" style="min-height: 50px;" :centered="false" size="s" level="4">
            {{ $i18n.locale === 'ru' ? title : titleEn || title}}
          </q-header>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" md="4" class="pr-0">
          <p class="text-black">{{date.toLowerCase()}}</p>
        </b-col>
        <b-col cols="9" md="8">
          <p class="d-flex reading-time">
            <img src="~@/assets/images/site/icons/time.svg" alt="" class="my-auto clock-icon">
            {{ $i18n.locale === 'ru' ? readingTime : readingTimeEn || readingTime }}
          </p>
        </b-col>
      </b-row>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "LinkCard",
  props: {
    category: {type: String, default: "Криптовалюта"},
    categoryEn: {type: String, default: "Криптовалюта"},
    title: {type: String, default: "Bitcoin Ta: вот почему BTC все еще находится под угрозой падения"},
    titleEn: {type: String, default: "Bitcoin Ta: вот почему BTC все еще находится под угрозой падения"},
    date: {type: String, default: "25 января"},
    readingTime: {type: String, default: '5 мин на прочтение'},
    readingTimeEn: {type: String, default: '5 мин на прочтение'},
    slug: {type: String, default: '/'}
  },
  methods: {
   
  }
}
</script>

<style scoped lang="scss">
.star-icon {
  height: 12px;
  margin-top: -3px;
  margin-right: 3px;
}

.reading-time {
  color: #A9A9A9;
}

.clock-icon {
  margin-right: 3px;
}

</style>
