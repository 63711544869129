

    import $request from "@/modules/request/"

    export default {

        dataSource: {
            // current_page: 1,
            // has_more_pages: false,
            // last_page: 1,
            // total_count: 0,
            items: []
        },

        categories: [],
        similar: [],

        get(cat=null, page=1) {
            $request.get("knowledge.list", {
                cat: cat,
                page: page,
                locale: localStorage.getItem('current_locale') || 'ru'
            }).then( rsp => {
                
                this.categories = [];
                this.similar = rsp.similar;

                Object.keys( rsp ).forEach( key => {
                    if( this.dataSource.hasOwnProperty( key ) ) {
                        this.dataSource[ key ] = rsp[key];
                    }
                });

                rsp.categories.forEach(cat => {
                    let subcategories = [];

                    cat.posts.forEach(post => {
                        subcategories.push({
                            link: '/knowledge/'+post.slug,
                            name: post.header,
                            name_en: post.header_en,
                        });
                    });

                    this.categories.push({
                        name: cat.title,
                        name_en: cat.title_en,
                        subcategories: subcategories
                    });

                });
            });
        }

    }
