<template>
  <b-container class="container-px-lg knowledge-page">
    <b-row>
      <b-col class="mt-1">
        <span style="color: gray">
          <router-link class="text-black" to="/">{{$t('crumbs.home')}}</router-link>
           / {{$t('crumbs.knowledge')}} 
          <span v-if="selected"> 
            / {{ $i18n.locale === 'ru' ? selected.header : selected.header_en || selected.header}}
          </span>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <q-header :centered="false" level="1" class="my-3 name_text" size="xxl">
          {{$t('knowledge.header-a')}}<br>{{$t('knowledge.header-b')}}<br class="d-md-none">{{$t('knowledge.header-c')}}<span
            class="vacancy-ellipse position-relative zindex-2">Qubity<img
            alt="" src="~@/assets/images/site/vacancies_ellipsis.svg"></span>
            {{$t('knowledge.header-d')}}
          <b><br>{{$t('knowledge.header-e')}}</b>
        </q-header>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(card, index) in knowledge.similar" :key="index" cols="12" md="6" xl="3" style="display: flex; flex-direction: column; justify-content: flex-end;">
        <LinkCard
            :category="card.kncategory.title"
            :categoryEn="card.kncategory.title_en"
            :date="card.date"
            :readingTime="card.time_read"
            :readingTimeEn="card.time_read_en"
            :title="card.header"
            :titleEn="card.header_en"
            :slug="card.slug"
        />
        <hr class="w-100 mt-0">
      </b-col>
    </b-row>
    <b-row class="d-flex flex-column-reverse flex-lg-row mt-2">
      <validation-observer ref="callbackForm" class="knowledge-form position-relative">
        <b-form class="d-flex d-lg-none flex-column p-2" @reset="onReset" @submit.prevent="ticket">
          <q-header :centered="false" class="mb-1" color="var(--text-primary-color)" level="1" size="m">
            <b>{{$t('knowledge.callback.header')}}</b>
          </q-header>
          <b-form-group
              id="group_1"
              :label="$t('knowledge.callback.labels.msg')"
              label-for="input-1"
          >
            <validation-provider
                #default="{ errors }"
                name="Message"
                rules="required"
            >
              <b-form-textarea
                  id="message"
                  v-model="form.message"
                  :state="errors.length > 0 ? false : null"
                  no-resize
                  :placeholder="$t('knowledge.callback.placeholders.msg')"
                  rows="3"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
              id="group_2"
              :label="$t('knowledge.callback.labels.user')"
              label-for="input-1"
          >
            <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
            >
              <b-form-input
                  id="number"
                  v-model="form.name"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('knowledge.callback.placeholders.user')"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
              id="group_2"
              :label="$t('knowledge.callback.labels.phone')"
              label-for="input-1"
              class="phone-number-input"
          >
            <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
            >
              <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge phone-group"
              >
                <b-input-group-prepend :class="country ? 'cb-flags' : 'hidden'" is-text>
                  <b-link v-if="country" class="text-black r-border0"
                          style="font-size: 13px; display: flex; align-items: center;"
                          @click="showCountryModal = true">
                    <country-flag :country="country.iso" size='small'/>
                    <span>{{ country.code }}</span>
                  </b-link>
                </b-input-group-prepend>
                <b-form-input
                    id="phone"
                    v-model="form.phoneNumber"
                    v-mask="country ? country.mask : '+7 (###) ###-##-##'"
                    :class="country ? `l-border0` : `l-border100 p-f` "
                    :placeholder="
                          country ? country.mask : '+7 (###) ###-##-##'"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="register-phone"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-col cols="12" md="4" sm="6">
            <q-btn block small-padding type="submit" variant="primary">{{$t('knowledge.btn')}}</q-btn>
          </b-col>
        </b-form>
        <div :class="successSubmitted && 'show-submitted'" class="success-submitted d-flex flex-column d-lg-none">
          <img alt="" class="mx-auto mb-2 mt-3" src="@/assets/images/site/icons/success.svg" width="150">
          <q-header class="mb-2" size="m"><b>{{$t('knowledge.response.msg-a')}}</b></q-header>
          <div class="text-center mb-2">
            {{$t('knowledge.response.msg-b')}}
          </div>
        </div>
      </validation-observer>
      <b-col cols="12" lg="9">
        <router-view class="page-content"/>
      </b-col>
      <b-col cols="12" lg="3">
        <CategoryCollapse
            v-for="(category, index) in knowledge.categories"
            :key="index"
            :company-links="category.subcategories"
            :selected="selected"
        >
          {{ $i18n.locale === 'ru' ? category.name : category.name_en || category.name }}
        </CategoryCollapse>
        <validation-observer ref="callbackForm">
          <div class="position-relative knowledge-form">
            <b-form class="d-lg-flex d-none flex-column p-2" @reset="onReset" @submit.prevent="ticket">
              <q-header :centered="false" class="mb-1" color="var(--text-primary-color)" level="1" size="m">
                <b>{{$t('knowledge.callback.header')}}</b>
              </q-header>
              <b-form-group
                  id="group_1"
                  :label="$t('knowledge.callback.labels.msg')"
                  label-for="input-1"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Message"
                    rules="required"
                >
                  <b-form-textarea
                      id="message"
                      v-model="form.message"
                      :state="errors.length > 0 ? false : null"
                      no-resize
                      :placeholder="$t('knowledge.callback.placeholders.msg')"
                      rows="3"
                      type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  id="group_2"
                  :label="$t('knowledge.callback.labels.user')"
                  label-for="input-1"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                >
                  <b-form-input
                      id="number"
                      v-model="form.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('knowledge.callback.placeholders.user')"
                      type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  id="group_2"
                  :label="$t('knowledge.callback.labels.phone')"
                  label-for="input-1"
                  class="phone-number-input"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                >
                  <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      class="input-group-merge phone-group"
                  >
                    <b-input-group-prepend :class="country ? 'cb-flags' : 'hidden'" is-text>
                      <b-link v-if="country" class="text-black r-border0"
                              style="font-size: 13px; display: flex; align-items: center;"
                              @click="showCountryModal = true">
                        <country-flag :country="country.iso" size='small'/>
                        <span>{{ country.code }}</span>
                      </b-link>
                    </b-input-group-prepend>
                    <b-form-input
                        id="phone"
                        v-model="form.phoneNumber"
                        v-mask="country ? country.mask : '+7 (###) ###-##-##'"
                        :class="country ? `l-border0` : `l-border100 p-f` "
                        :placeholder="
                          country ? country.mask : '+7 (###) ###-##-##'"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="register-phone"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <q-btn class="mx-auto" small-padding type="submit" variant="primary">{{$t('knowledge.btn')}}</q-btn>
            </b-form>
            <div :class="successSubmitted && 'show-submitted'" class="success-submitted d-lg-flex flex-column d-none">
              <img alt="" class="w-50 mx-auto mb-2 mt-3" src="@/assets/images/site/icons/success.svg">
              <q-header class="mb-2" size="m"><b>{{$t('knowledge.response.msg-a')}}</b></q-header>
              <div class="text-center mb-2">
                {{$t('knowledge.response.msg-b')}}
              </div>
            </div>
          </div>
        </validation-observer>
      </b-col>
    </b-row>
    <b-modal
        v-model="showCountryModal"
        centered
        modal-class="with-no-paddings"
        ok-only
        :ok-title="$t('modals.country.ok')"
        :title="$t('modals.country.title')"
    >

      <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
        <b-list-group-item v-for="c in countries" :key="c.iso" :active=" country ? (c.iso === country.iso) : false "
                           style="cursor: pointer;" @click="country = c; showCountryModal = false;">
          <div class="d-flex justify-content-start align-items-center">
            <div style="margin-right: 8px;">
              <!-- <b-img :src="c.flag" fluid /> -->
              <country-flag :country="c.iso" size='small'/>
            </div>
            <div class="flex-grow-1">
              {{ c.name }}
            </div>
            <div>
              {{ c.code }}
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

    </b-modal>
  </b-container>
</template>

<script>
import LinkCard from "@/views/Main/main-design-v2/views/knowledge/components/LinkCard";
import CategoryCollapse from "@/views/Main/main-design-v2/views/knowledge/components/CategoryCollapse";
import Input from "@/views/Administrator/Finance/modals/input";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import knowledge from "@/modules/contents/knowledge"

export default {
  metaInfo() {
    const title = this.$t('title-pages.knowledge');
    return {
      title: title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Узнавайте важные нюансы, полезные советы и управляйте криптовалютой грамотно'
        }
      ]
    }
  },
  methods: {
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    ticket() {
      this.$refs.callbackForm.validate().then((success) => {
        if (success) {
          this.$request.post("integrations/amocrm.ticket", {
            name: this.form.name,
            phone: this.country ? `${this.country.code}${this.form.phoneNumber}` : this.form.phoneNumber,
            message: this.form.message,
            utm_tags: localStorage.getItem("utmTags") ? JSON.parse(localStorage.getItem("utmTags")) : null
          })
              .then((result) => {
                this.successSubmitted = true
              })
              .catch((err) => {
                // this.error = err.message;
              });
        }
      });
    }
  },
  components: {Input, LinkCard, CategoryCollapse, ValidationProvider, ValidationObserver},
  data() {
    return {
      knowledge,
      selected: null,
      form: {
        message: "",
        name: "",
        phoneNumber: ""
      },
      countries: require("countries-phone-masks"),
      showCountryModal: false,
      country: {
        "name": "Russia",
        "code": "+7",
        "iso": "RU",
        "flag": "https://www.countryflags.io/RU/flat/24.png",
        "mask": "(###)###-##-##"
      },
      successSubmitted: false,
    }
  },
  watch: {
    "knowledge.dataSource.items" : function() {
      this.selected = knowledge.dataSource.items.find(item => item.slug === this.$route.params.slug );

      if (knowledge.dataSource.items.length > 0 && this.$route.params.slug === undefined) {
        this.$router.push({ path: "/knowledge/" + knowledge.dataSource.items[knowledge.dataSource.items.length - 1].slug});
      }
    },
    "$route.params.slug": function () {
      this.selected = knowledge.dataSource.items.find(item => item.slug === this.$route.params.slug );
    }
  },
  mounted() {
    knowledge.get();
  }
}
</script>

<style lang="scss">
.knowledge-form {
  box-shadow: 0 64px 64px rgba(31, 47, 70, 0.12);
  border-radius: 32px;
  overflow: hidden;

  *:focus {
    box-shadow: none !important;
  }

  label {
    color: #000;
  }

  input, textarea {
    padding: 0 !important;
    border: none;
    border-radius: 0;
    font-size: 14px;
    border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
    overflow-x: hidden;
  }

  input::placeholder,
  textarea::placeholder {
    font-weight: bolder;
  }

  button[type="submit"] {
    border-radius: 30px;
  }
}

.success-submitted {
  overflow: hidden;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  transition: all .3s ease;
  padding: 0 16px 0 16px;
}

.show-submitted {
  height: 100%;
  padding: 16px;
}

.page-content {
  h1 {
    font-weight: bolder;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: bolder;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 24px;
  }

  p {
    font-size: 18px;
  }

  a {
    color: inherit;
    text-decoration: none;
    text-underline-offset: 2px;
  }

  ol {
    padding: 0;
    list-style-position: inside;
    margin-bottom: 30px;
  }

  li {
    font-size: 18px;
    margin-top: 8px;
  }

  .page-content-btn {
    border: 1px solid rgba(169, 169, 169, 0.3) !important;
    margin-top: 10px;
    color: #426FF6;
    text-decoration: none;
  }
}

.knowledge-page {
  margin-top: 24px;

  .phone-number-input {
    .input-group:not(.bootstrap-touchspin):focus-within {
      box-shadow: none;
    }

    .cb-flags .input-group-text a:focus {
      background: none!important;
    }

    .cb-flags .input-group-text {
      background: none!important;
      border-color: white!important;
      padding-left: 0;
    }

    .input-group {
      border-radius: 0;
      input {
        border-radius: 0!important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.vacancy-ellipse {
  img {
    position: absolute;
    z-index: -1;
    width: 110%;
    right: -5px;
  }
}
</style>
