<template>
  <div class="faq-item pl-1 mb-1">
    <div class="faq-question d-flex justify-content-between" @click="toggleFooterLinkGroupList"  style="padding: 10px">
      <span class="my-auto">
        <q-header level="2" size="s" color="var(--text-primary-color)"><b><slot/></b></q-header>
      </span>
      <span class="icon my-auto">
        <img :class="!isOpen && 'icon-faq-plus'" alt="" class="icon-symbol d-none d-lg-block" src="@/assets/images/site/icons/plus_in_circle.svg">
        <img :class="isOpen && 'icon-arrow-up'" alt="" class="icon-symbol d-lg-none" src="@/assets/images/site/icons/collapse_arrow.svg">
      </span>
    </div>
    <b-collapse id="faq-item" :visible="isOpen">
      <div class="d-flex flex-column pb-1" style="padding: 0 10px">
        <router-link v-for="(companyLink, index) in companyLinks" :key="index" :class="isActive(companyLink.link) ? 'footer-link-active' : 'footer-link'" :to="companyLink.link">
          <img src="@/assets/images/site/icons/Shape1.svg" alt="" style="margin-right: 2px;">
          {{ $i18n.locale === 'ru' ? companyLink.name : companyLink.name_en || companyLink.name }}
        </router-link>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "CategoryCollapse",
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleFooterLinkGroupList() {
      this.isOpen = !this.isOpen
    },
    isActive(link) {
      if (link.substring(link.lastIndexOf('/') + 1) == this.$route.params.slug) return true;
      else false;
    }
  },
  props: {
    companyLinks: {type: Array}
  },
  watch: {
    "$route.params.slug": function () {
      if (this.$route.params.slug !== undefined) {
        this.companyLinks.forEach(companyLink => {
          if (this.isActive(companyLink.link)) this.isOpen = true;
        });
      }
    }
  },
  mounted() {
    if (this.$route.params.slug !== undefined) {
      this.companyLinks.forEach(companyLink => {
        if (this.isActive(companyLink.link)) this.isOpen = true;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
span, .faq-answer, .footer-link {
  color: var(--text-primary-color);
}

.faq-item {
  background: #F4F5F6;
  border-radius: 32px;
}

.footer-link {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 8px;
  transition: .3s;

  img {
    filter: brightness(0);
  }
}

.footer-link:hover {
  color: var(--theme-accent-light);

  img {
    filter: none;
  }
}

.faq-question {
  font-size: 24px;

  span {
    cursor: pointer;
  }

  .icon {
    transition: all .3s ease;
    border-radius: 100px;
    aspect-ratio: 1;
    width: 28px;
    height: 28px;
    display: flex;
    padding: 4px;

    .icon-symbol {
      transition: all .3s ease;
      width: 100%;
      filter: invert(1);
    }
  }
}

.icon-faq-plus {
  transform: rotate(45deg);
}

.icon-arrow-up {
  transform: rotate(180deg);
}
</style>
